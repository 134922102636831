import { Input, notify } from '@affine/component';
import { SelectPermissions } from '@affine/component/affine-select-permission';
import type { InviteModalProps } from '@affine/component/member-components';
import {
  InviteModal,
  MemberLimitModal,
  Pagination,
} from '@affine/component/member-components';
import { SettingRow } from '@affine/component/setting-components';
import { Avatar } from '@affine/component/ui/avatar';
import { Button, IconButton } from '@affine/component/ui/button';
import { Loading } from '@affine/component/ui/loading';
import { Menu, MenuItem } from '@affine/component/ui/menu';
import { Tooltip } from '@affine/component/ui/tooltip';
import { AffineErrorBoundary } from '@affine/core/components/affine/affine-error-boundary';
import { openSettingModalAtom } from '@affine/core/components/atoms';
import { useInviteMember } from '@affine/core/components/hooks/affine/use-invite-member';
import { useRevokeMemberPermission } from '@affine/core/components/hooks/affine/use-revoke-member-permission';
import { useChangePermissionUser } from '@affine/core/components/hooks/affine/use-change-permission-user';
import { usePermission } from '@affine/core/components/hooks/use-permission';
import {
  type Member,
  WorkspaceMembersService,
  WorkspacePermissionService,
} from '@affine/core/modules/permissions';
import { WorkspaceQuotaService } from '@affine/core/modules/quota';
import { WorkspaceFlavour } from '@affine/env/workspace';
import { UserAccessRole, UserFriendlyError } from '@affine/graphql';
import { useI18n } from '@affine/i18n';
import { track } from '@affine/track';
import { MoreVerticalIcon, SearchIcon } from '@blocksuite/icons/rc';
import { cssVar } from '@docnboard/theme';
import {
  useEnsureLiveData,
  useLiveData,
  useService,
  WorkspaceService,
} from '@toeverything/infra';
import clsx from 'clsx';
import { useSetAtom } from 'jotai';
import { clamp, debounce } from 'lodash-es';
import type { ReactElement } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  type AuthAccountInfo,
  AuthService,
  ServerConfigService,
  SubscriptionService,
} from '../../../../../modules/cloud';
import * as style from './style.css';

type OnRevoke = (memberId: string) => void;
type onChange = (memberId: string, UserAccessRole: UserAccessRole) => void;

const MembersPanelLocal = () => {
  const t = useI18n();
  return (
    <Tooltip content={t['com.affine.settings.member-tooltip']()}>
      <div className={style.fakeWrapper}>
        <SettingRow name={`${t['Members']()} (0)`} desc={t['Members hint']()}>
          <Button>{t['Invite Members']()}</Button>
        </SettingRow>
      </div>
    </Tooltip>
  );
};

export const CloudWorkspaceMembersPanel = () => {
  const serverConfig = useService(ServerConfigService).serverConfig;
  const hasPaymentFeature = useLiveData(
    serverConfig.features$.map(f => f?.payment)
  );
  const workspace = useService(WorkspaceService).workspace;
  const membersService = useService(WorkspaceMembersService);

  const permissionService = useService(WorkspacePermissionService);
  const isOwner = useLiveData(permissionService.permission.isOwner$);
  //DNB_FIX
  const permissions = useLiveData(permissionService.permission.permissions$);
  useEffect(() => {
    permissionService.permission.revalidate();
  }, [permissionService]);

  const workspaceQuotaService = useService(WorkspaceQuotaService);
  useEffect(() => {
    workspaceQuotaService.quota.revalidate();
  }, [workspaceQuotaService]);
  const isLoading = useLiveData(workspaceQuotaService.quota.isLoading$);
  const error = useLiveData(workspaceQuotaService.quota.error$);
  const workspaceQuota = useLiveData(workspaceQuotaService.quota.quota$);
  const subscriptionService = useService(SubscriptionService);
  const plan = useLiveData(
    subscriptionService.subscription.pro$.map(s => s?.plan)
  );
  const isLimited =
    workspaceQuota && workspaceQuota.memberLimit
      ? workspaceQuota.memberCount >= workspaceQuota.memberLimit
      : null;

  const t = useI18n();
  const { invite, isMutating } = useInviteMember(workspace.id);
  const revokeMemberPermission = useRevokeMemberPermission(workspace.id);
  const { change } = useChangePermissionUser(workspace.id);

  const [open, setOpen] = useState(false);

  const openModal = useCallback(() => {
    setOpen(true);
  }, []);

  const onInviteConfirm = useCallback<InviteModalProps['onConfirm']>(
    async ({ email, permission }) => {
      const success = await invite(email, permission, true);
      if (success) {
        notify.success({
          title: t['Invitation sent'](),
          message: t['Invitation sent hint'](),
        });
        setOpen(false);
        membersService.members.revalidate();
      }
    },
    [membersService.members, invite, t]
  );

  const setSettingModalAtom = useSetAtom(openSettingModalAtom);
  const handleUpgradeConfirm = useCallback(() => {
    setSettingModalAtom({
      open: true,
      activeTab: 'plans',
      scrollAnchor: 'cloudPricingPlan',
    });
    track.$.settingsPanel.workspace.viewPlans({
      control: 'inviteMember',
    });
  }, [setSettingModalAtom]);

  const onRevoke = useCallback<OnRevoke>(
    async memberId => {
      const res = await revokeMemberPermission(memberId);
      if (res?.revoke) {
        notify.success({ title: t['Removed successfully']() });
        membersService.members.revalidate();
      }
    },
    [revokeMemberPermission, t]
  );

  const onChange = useCallback<onChange>(
    async (memberId, permission: UserAccessRole) => {
      const res = await change(memberId, permission);
      // DNB_FIX исправить проверку и добавить обновление данных
      if (res.changeUserAccess) {
        notify.success({
          title: t['Changed successfully'](),
        });
        membersService.members.revalidate();
      }
    },
    [change, t]
  );

  // DNB_FIX убрал описание количество человек в Workspace и его limit, если isSelfHosted
  const desc = useMemo(() => {
    if (!workspaceQuota || BUILD_CONFIG.isSelfHosted) return null;
    return (
      <span>
        {t['com.affine.payment.member.description2']()}
        {hasPaymentFeature ? (
          <div
            className={style.goUpgradeWrapper}
            onClick={handleUpgradeConfirm}
          >
            <span className={style.goUpgrade}>
              {t['com.affine.payment.member.description.choose-plan']()}
            </span>
          </div>
        ) : null}
      </span>
    );
  }, [handleUpgradeConfirm, hasPaymentFeature, t, workspaceQuota]);

  if (workspaceQuota === null) {
    if (isLoading) {
      return <MembersPanelFallback />;
    } else {
      return (
        <span style={{ color: cssVar('errorColor') }}>
          {error
            ? UserFriendlyError.fromAnyError(error).message
            : 'Failed to load members'}
        </span>
      );
    }
  }

  return (
    <>
      <SettingRow
        name={`${t['Members']()} (${workspaceQuota.memberCount}/${workspaceQuota.displayFormat.memberLimit})`}
        desc={desc}
      >
        {/* DNB_FIX добавление пользователей не только для Owner*/}
        <Button onClick={openModal}>{t['Invite Members']()}</Button>
        {isLimited ? (
          <MemberLimitModal
            isFreePlan={!!plan}
            open={open}
            plan={workspaceQuota.displayFormat.name ?? ''}
            quota={workspaceQuota.displayFormat.memberLimit ?? ''}
            setOpen={setOpen}
            onConfirm={handleUpgradeConfirm}
          />
        ) : (
          <InviteModal
            open={open}
            setOpen={setOpen}
            onConfirm={onInviteConfirm}
            isMutating={isMutating}
            //DNB_FIX
            permissions={permissions}
          />
        )}
      </SettingRow>

      <div className={style.membersPanel}>
        <MemberList
          isOwner={!!isOwner}
          onRevoke={onRevoke}
          onChange={onChange}
        />
      </div>
    </>
  );
};
export const MembersPanelFallback = () => {
  const t = useI18n();

  return (
    <>
      <SettingRow
        name={t['Members']()}
        desc={t['com.affine.payment.member.description2']()}
      />
      <div className={style.membersPanel}>
        <MemberListFallback memberCount={1} />
      </div>
    </>
  );
};

const MemberListFallback = ({ memberCount }: { memberCount?: number }) => {
  // prevent page jitter
  const height = useMemo(() => {
    if (memberCount) {
      // height and margin-bottom
      return memberCount * 58 + (memberCount - 1) * 6;
    }
    return 'auto';
  }, [memberCount]);
  const t = useI18n();

  return (
    <div
      style={{
        height,
      }}
      className={style.membersFallback}
    >
      <Loading size={20} />
      <span>{t['com.affine.settings.member.loading']()}</span>
    </div>
  );
};

// DNB_FIX
const MemberListEmpty = ({ memberCount }: { memberCount?: number }) => {
  // prevent page jitter
  const height = useMemo(() => {
    if (memberCount) {
      // height and margin-bottom
      return memberCount * 58 + (memberCount - 1) * 6;
    }
    return 'auto';
  }, [memberCount]);
  const t = useI18n();

  return (
    <div
      style={{
        height,
      }}
      className={style.membersEmpty}
    >
      <span>{t['com.affine.settings.member.empty']()}</span>
    </div>
  );
};

// DNB_FIX добавить prop search и убрать лишние props
const MemberList = ({
  isOwner,
  onRevoke,
  onChange,
}: {
  isOwner: boolean;
  onRevoke: OnRevoke;
  onChange: onChange;
}) => {
  const t = useI18n();
  const membersService = useService(WorkspaceMembersService);
  const memberCount = useLiveData(membersService.members.memberCount$);
  const pageNum = useLiveData(membersService.members.pageNum$);
  const isLoading = useLiveData(membersService.members.isLoading$);
  const error = useLiveData(membersService.members.error$);
  const pageMembers = useLiveData(membersService.members.pageMembers$);
  const search = useLiveData(membersService.members.search$);

  // DNB_FIX добавить обновление state при изменении значения input с debounce
  const onChangeSearch = debounce(value => {
    membersService.members.setSearch(value);
    // TODO DNB_FIX ставим всегда 0 page
    membersService.members.setPageNum(0);
    membersService.members.revalidate();
  }, 200);

  useEffect(() => {
    membersService.members.revalidate();
  }, [membersService]);

  const session = useService(AuthService).session;
  const account = useEnsureLiveData(session.account$);

  const handlePageChange = useCallback(
    (_: number, pageNum: number) => {
      membersService.members.setPageNum(pageNum);
      membersService.members.revalidate();
    },
    [membersService]
  );

  return (
    <div className={style.memberList}>
      {/* DNB_FIX добавить компоненту Input для поиска */}
      <Input
        onChange={onChangeSearch}
        defaultValue={search || ''}
        placeholder={t['com.affine.settings.member.placeholder']()}
        preFix={<SearchIcon className={style.searchIcon} />}
      />
      {pageMembers === undefined ? (
        isLoading ? (
          <MemberListFallback
            memberCount={
              memberCount
                ? clamp(
                    memberCount - pageNum * membersService.members.PAGE_SIZE,
                    1,
                    membersService.members.PAGE_SIZE
                  )
                : 1
            }
          />
        ) : (
          <span style={{ color: cssVar('errorColor') }}>
            {error
              ? UserFriendlyError.fromAnyError(error).message
              : 'Failed to load members'}
          </span>
        )
      ) : // DNB_FIX
      pageMembers.length === 0 ? (
        <MemberListEmpty
          memberCount={
            memberCount
              ? clamp(
                  memberCount - pageNum * membersService.members.PAGE_SIZE,
                  1,
                  membersService.members.PAGE_SIZE
                )
              : 1
          }
        />
      ) : (
        pageMembers?.map(member => (
          <MemberItem
            currentAccount={account}
            key={member.id}
            member={member}
            isOwner={isOwner}
            onRevoke={onRevoke}
            onChange={onChange}
          />
        ))
      )}
      {memberCount !== undefined &&
        memberCount > membersService.members.PAGE_SIZE && (
          <Pagination
            totalCount={memberCount}
            countPerPage={membersService.members.PAGE_SIZE}
            pageNum={pageNum}
            onPageChange={handlePageChange}
          />
        )}
    </div>
  );
};

const MemberItem = ({
  member,
  isOwner,
  currentAccount,
  onRevoke,
  onChange,
}: {
  member: Member;
  isOwner: boolean;
  currentAccount: AuthAccountInfo;
  onRevoke: OnRevoke;
  onChange: onChange;
}) => {
  const t = useI18n();

  const handleRevoke = useCallback(() => {
    onRevoke(member.id);
  }, [onRevoke, member.id]);

  const handleChangePermission = (
    userId: string,
    permission: UserAccessRole
  ) => {
    onChange(userId, permission);
  };

  const { getName } = usePermission();

  const operationButtonInfo = useMemo(() => {
    return {
      show: isOwner && currentAccount.id !== member.id,
      leaveOrRevokeText: t['Remove from workspace'](),
      change: isOwner,
    };
  }, [currentAccount.id, isOwner, member.id, t]);

  return (
    <div
      key={member.id}
      className={style.memberListItem}
      data-testid="member-item"
    >
      <Avatar
        size={36}
        url={member.avatarUrl}
        name={(member.name ? member.name : member.email) as string}
      />
      <div className={style.memberContainer}>
        {member.name ? (
          <>
            <div className={style.memberName}>{member.name}</div>
            <div className={style.memberEmail}>{member.email}</div>
          </>
        ) : (
          <div className={style.memberName}>{member.email}</div>
        )}
      </div>
      <div
        className={clsx(style.roleOrStatus, {
          pending: !member.isActivated,
        })}
      >
        {member.isActivated ? (
          // DNB_FIX добавить условие для нескольких ролей/permissions
          member.access === UserAccessRole.Owner ? (
            <span className={style.memberPermision}>
              {getName(member.access)}
            </span>
          ) : // DNB_FIX использовать свою компоненту select-permission
          operationButtonInfo.change ? (
            <SelectPermissions
              value={member.access}
              onChange={(value: UserAccessRole) =>
                handleChangePermission(member.id, value)
              }
            />
          ) : (
            //DNB_FIX выводить просто имя, если изменять нельзя: только Owner
            <span className={style.memberPermision}>
              {getName(member.access)}
            </span>
          )
        ) : (
          <span className={style.memberPermision}>{t['Pending']()}</span>
        )}
      </div>
      <Menu
        items={
          <MenuItem
            style={{ color: 'var(--affine-error-color)' }}
            data-member-id={member.id}
            onClick={handleRevoke}
          >
            {operationButtonInfo.leaveOrRevokeText}
          </MenuItem>
        }
      >
        <IconButton
          disabled={!operationButtonInfo.show}
          style={{
            visibility: operationButtonInfo.show ? 'visible' : 'hidden',
            flexShrink: 0,
          }}
        >
          <MoreVerticalIcon />
        </IconButton>
      </Menu>
    </div>
  );
};

export const MembersPanel = (): ReactElement | null => {
  const workspace = useService(WorkspaceService).workspace;
  if (workspace.flavour === WorkspaceFlavour.LOCAL) {
    return <MembersPanelLocal />;
  }
  return (
    <AffineErrorBoundary>
      <CloudWorkspaceMembersPanel />
    </AffineErrorBoundary>
  );
};
