import { notify } from '@affine/component';
import { InformationFillDuotoneIcon } from '@blocksuite/icons/rc';
import { useRegisterSW } from '@docnboard/pwa/react';
import { useCallback, useEffect } from 'react';

export function UpdateVersionProvider() {
  const {
    needRefresh: [needRefresh, setNeedRefresh],
  } = useRegisterSW();

  const close = useCallback(() => {
    setNeedRefresh(false);
  }, [setNeedRefresh]);

  useEffect(() => {
    if (needRefresh) {
      notify.warning(
        {
          icon: <InformationFillDuotoneIcon />,
          theme: 'warning',
          style: 'normal',
          message: 'New version available, click on "Update" button to update.',
          title: 'New version application',
          onDismiss: close,
          action: {
            autoClose: false,
            label: 'Update',
            buttonProps: {
              variant: 'secondary',
            },
            onClick: () => window.location.reload(),
          },
        },
        {
          position: 'top-right',
          duration: Infinity,
        }
      );
    }
  }, [needRefresh, close]);

  return null;
}
