import "../../component/src/ui/notification/styles.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../component/src/ui/notification/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA31TwW7bMAy99yu4AAOWgwo7TbpWRb+k6EG2aJubLBkSHScb+u+DpKR1Uq9H8pGPfE/UbT+Nd2V1D39vACrnNXrhlaYxSHgYDk8f2Yk0dxLKi2Tgo0EJwRnSOX0QoVPaTRL2yv8QQjUNWTwlRblOVar+3Xo3Wi1qZ5w/1+ZdivVswAK+SfgCEMnfbm5z8DMpGpTWZFsJ5X3c+x19SKimMBh1lNAYTKqUodYKYuxDTorAynOEfo2BqTmK2llGyxLCoGoUFfKEaGfUj4m6Q2o7lrDZZr++HlWjZfQzEpVITpb/l2MwcYUrDoBe+Zas8HmDsrhQriDs2zl7WRTfn2Ybn+MFg7dzg6s5SRE7kmGtj29fxtjt0Tcmhh1pHU0CYDyw+ADQGBoChQhNHTGKZKsE6yavhsTqLIvptNy2SJNMPKlri1NhoD8oodzl1JdO1EnAVcnlmeh8RC4Qk7MSPBrFtMdZCb5oxUo0xil+XrEfcfV61aWq4MzImPS74TwFYHlok9oX7N+t4Rv1g/OsLM8a2rmQNGD3yZGLz5jz/fqzlZu8SdY0EXeCamdf4TwpA+l2n1dMbN7V5o8mDDYs4S49yds/8DdYdF0EAAA=\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var action = 'mwu31bc';
export var actionButton = 'mwu31bd';
export var actionTextColor = 'var(--mwu31b3)';
export var card = 'mwu31b6';
export var cardBorderColor = 'var(--mwu31b2)';
export var cardColor = 'var(--mwu31b0)';
export var cardForeground = 'var(--mwu31b1)';
export var cardInner = 'mwu31b7';
export var closeButton = 'mwu31be';
export var closeIcon = 'mwu31bf';
export var closeIconColor = 'var(--mwu31b5)';
export var headAlignWrapper = 'mwu31b9';
export var header = 'mwu31b8';
export var icon = 'mwu31ba';
export var iconColor = 'var(--mwu31b4)';
export var main = 'mwu31bg';
export var title = 'mwu31bb';