import { DebugLogger } from '@affine/debug';
import { WorkspaceFlavour } from '@affine/env/workspace';
import { UserAccessRole } from '@affine/graphql';
import type { WorkspaceService } from '@toeverything/infra';
import {
  backoffRetry,
  catchErrorInto,
  effect,
  Entity,
  fromPromise,
  LiveData,
  onComplete,
  onStart,
} from '@toeverything/infra';
import { EMPTY, exhaustMap, mergeMap } from 'rxjs';

import { isBackendError, isNetworkError } from '../../cloud';
import type { WorkspacePermissionStore } from '../stores/permission';

// DNB_NEW_NAMES_FIX
const logger = new DebugLogger('dnb:workspace-permission');
//DNB_FIX
const getPermissions = (permissionsRoles: UserAccessRole[]) => {
  if (
    permissionsRoles.some(
      permission =>
        permission === UserAccessRole.Owner ||
        permission === UserAccessRole.Admin ||
        permission === UserAccessRole.Write
    )
  ) {
    return [UserAccessRole.Read, UserAccessRole.Write];
  } else {
    return [UserAccessRole.Read];
  }
};

export class WorkspacePermission extends Entity {
  isOwner$ = new LiveData<boolean | null>(null);
  // DNB_FIX добавить permissionsRoles и permissions
  permissionsRoles$ = new LiveData<UserAccessRole[] | null>(null);
  permissions$ = this.permissionsRoles$.map(permissionsRoles =>
    permissionsRoles ? getPermissions(permissionsRoles) : []
  );
  isReadOnly$ = this.permissions$.map(
    permissions =>
      !!permissions?.length &&
      permissions?.every(permission => permission === UserAccessRole.Read)
  );
  isLoading$ = new LiveData(false);
  error$ = new LiveData<any>(null);

  constructor(
    private readonly workspaceService: WorkspaceService,
    private readonly store: WorkspacePermissionStore
  ) {
    super();
  }

  // DNB_FIX получать сразу и isOwner и permissions
  revalidate = effect(
    exhaustMap(() => {
      return fromPromise(async signal => {
        const result: {
          isOwner: boolean;
          permissions: UserAccessRole[] | null;
        } = {
          isOwner: true,
          permissions: null,
        };

        if (
          this.workspaceService.workspace.flavour ===
          // DNB_NEW_NAMES_FIX
          WorkspaceFlavour.DNB_CLOUD
        ) {
          result.isOwner = await this.store.fetchIsOwner(
            this.workspaceService.workspace.id,
            signal
          );
          result.permissions = await this.store.fetchPermisionAll(
            this.workspaceService.workspace.id,
            signal
          );
        }

        return result;
      }).pipe(
        backoffRetry({
          when: isNetworkError,
          count: Infinity,
        }),
        backoffRetry({
          when: isBackendError,
        }),
        mergeMap(result => {
          if (result) {
            this.isOwner$.next(result.isOwner);
            this.permissionsRoles$.next(result.permissions);
          }
          return EMPTY;
        }),
        // mapInto(this.isOwner$),
        catchErrorInto(this.error$, error => {
          logger.error('Failed to fetch isOwner', error);
        }),
        onStart(() => this.isLoading$.setValue(true)),
        onComplete(() => this.isLoading$.setValue(false))
      );
    })
  );

  override dispose(): void {
    this.revalidate.unsubscribe();
  }
}
