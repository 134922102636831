import type { UserAccessRole } from '@affine/graphql';
import { changePermissionUserWorkspaceMutation } from '@affine/graphql';
import { useCallback } from 'react';

import { useMutation } from '../use-mutation';
import { useMutateCloud } from './use-mutate-cloud';

export function useChangePermissionUser(workspaceId: string) {
  const { trigger, isMutating } = useMutation({
    mutation: changePermissionUserWorkspaceMutation,
  });
  const mutate = useMutateCloud();
  return {
    change: useCallback(
      async (userId: string, permission: UserAccessRole) => {
        const res = await trigger({
          workspaceId,
          userId,
          access: permission,
        });
        await mutate();
        return res;
      },
      [mutate, trigger, workspaceId]
    ),
    isMutating,
  };
}
