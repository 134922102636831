import { Workbox } from 'workbox-window';

import { type RegisterSWOptions } from '../types';

export const registerSW = (options: RegisterSWOptions = {}) => {
  const {
    immediate = false,
    onNeedRefresh,
    onRegisteredSW,
    onRegisterError,
  } = options;

  if ('serviceWorker' in navigator) {
    const wb = new Workbox('/service-worker.js', { scope: '/' });

    wb.addEventListener('installed', event => {
      if (event.isUpdate) {
        onNeedRefresh?.();
      }
    });

    wb.register({ immediate })
      .then(r => {
        if (onRegisteredSW) onRegisteredSW('/service-worker.js', r);
      })
      .catch(e => {
        onRegisterError?.(e);
      });
  }
};
