import { useState } from 'react';

import { type RegisterSWOptions } from '../types';
import { registerSW } from './register';

export function useRegisterSW(options: RegisterSWOptions = {}) {
  const {
    onNeedRefresh,
    onRegisterError,
    immediate,
    // onOfflineReady,
    onRegisteredSW,
  } = options;

  const [needRefresh, setNeedRefresh] = useState(false);

  useState(() => {
    return registerSW({
      onNeedRefresh() {
        setNeedRefresh(true);
        onNeedRefresh?.();
      },
      onRegisterError,
      immediate,
      // onOfflineReady,
      onRegisteredSW,
    });
  });

  return {
    needRefresh: [needRefresh, setNeedRefresh] as [
      boolean,
      typeof setNeedRefresh,
    ],
  };
}
